<template>
  <div class="wrap">
    <div class="title">
      组织架构
    </div>
    <div v-if="associationData" v-loading="loading">
      <div class="content" v-html="associationData.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "组织架构",
  },
  data(){
    return {
      url:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/organization2.png',
      loading:false,
      associationData:null
    }
  },
  mounted(){
    this.getList()
  },
  methods:{
    async getList(){
      this.loading=true;
      let params={}
      params.nopage=0
      params.source=this.PJSource
       params.source = this.PJSource;
      params.language = this.LOCALE === "en" ? 1 : 2;
      params.content_type = 10;
      let res = await this.$store.dispatch(
        "API_index/getStaticContent",
        params
      );
      if(res.success) {
        this.loading = false;
        this.associationData=res.data[0]
      }
    }
  }
};
</script>

<style scoped lang="less">
.title {
  font-size: 24px;
  font-weight: bold;
  color: #666666;
  margin-top: 13px;
  margin-bottom: 24px;
}
.img-wrap {
  margin-top: 24px;
  width: 100%;
  padding: 30px;
  background: #ffffff;
}
.content {
  padding: 30px 30px 58px;
  background: #ffffff;
  .marginT {
    margin-top: 20px;
  }
}
</style>
